import {
  type ClientLoaderFunctionArgs,
  json,
  Link,
  useLoaderData,
} from '@remix-run/react';
import { type AxiosResponse } from 'axios';
import { useMemo, useState } from 'react';
import { $path } from 'remix-routes';

import type {
  DtoBlock,
  DtoGame,
  DtoProgression,
  DtoSingleGamePackResponse,
} from '@lp-lib/api-service-client/public';

import { useUserAnalytics } from '../analytics/user';
import { RequireActivation } from '../components/Access';
import { UserAccess } from '../components/Access/UserAccess';
import { GlobalAudioDetectionProvider } from '../components/GameV2/apis/AudioDetection';
import { Overworld } from '../components/GameV2/Overworld';
import { PlayCursor } from '../components/GameV2/PlayCursor';
import { Playground } from '../components/GameV2/Playground';
import { StackedSquaresIcon } from '../components/icons/StackedSquaresIcon';
import { ProvidersList } from '../components/ProvidersList';
import { TrainingEditorUtils } from '../components/Training/Editor/utils';
import { UserContextProvider, useUser } from '../components/UserContext';
import { useLiveCallback } from '../hooks/useLiveCallback';
import { apiService } from '../services/api-service';
import { OrganizerRoleUtils } from '../types';
import type { Game } from '../types/game';
import { fromDTOGamePack, fromDTOGames } from '../utils/api-dto';
import { isForbidden, isNotFound, tokenWithRedirect } from '../utils/router';
import { setAPIServiceClientSecureToken } from '../utils/setAPIClientToken';

setAPIServiceClientSecureToken();

export const clientLoader = async (action: ClientLoaderFunctionArgs) => {
  const id = action.params.id;
  if (!id) {
    throw json({}, { status: 404 });
  }

  let resp: AxiosResponse<DtoSingleGamePackResponse>;
  try {
    resp = await tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          games: true,
        }),
      action.request.url,
      {
        // for now, require authentication. in the future, we may permit unauthenticated access...
        requireAuthentication: true,
        preferRedirect: 'login',
      }
    );
  } catch (e) {
    if (isForbidden(e) || isNotFound(e)) {
      // treat both errors as a 404.
      throw json({}, { status: 404 });
    } else {
      throw e;
    }
  }

  return { bundle: resp.data };
};

export function Component() {
  const { bundle } = useLoaderData<typeof clientLoader>();

  const providers = [
    <UserContextProvider useUserAnalytics={useUserAnalytics} />,
    <UserAccess allowGuests />,
    <RequireActivation />,
    <GlobalAudioDetectionProvider />,
  ];

  return (
    <ProvidersList providers={providers}>
      <Preview bundle={bundle} />
    </ProvidersList>
  );
}

function Preview(props: { bundle: DtoSingleGamePackResponse }) {
  const { gamePack, games = [], blocks = [] } = props.bundle;
  const user = useUser();
  const isOrgAdmin = OrganizerRoleUtils.isOwnerOrAdmin(user.organizer?.role);

  const classicGames = useMemo(() => fromDTOGames(games), [games]);
  const classicGamePack = useMemo(() => fromDTOGamePack(gamePack), [gamePack]);

  const [overworldProgression, setOverworldProgression] =
    useState<DtoProgression>(() =>
      TrainingEditorUtils.PreviewOverworldProgression(classicGames, 0)
    );

  const [cursor, setCursor] = useState<PlayCursor>(() => {
    const minigameBlocks = new Map<DtoGame['id'], DtoBlock[]>();
    for (const block of blocks) {
      if (!minigameBlocks.has(block.gameId)) {
        minigameBlocks.set(block.gameId, []);
      }
      minigameBlocks.get(block.gameId)?.push(block);
    }

    return new PlayCursor(gamePack, games, minigameBlocks, blocks, null, 0, 0);
  });

  const [view, setView] = useState<'overworld' | 'playground'>('overworld');

  const handlePlaygroundClose = useLiveCallback((cursor: PlayCursor) => {
    setOverworldProgression((prev) => {
      const next = TrainingEditorUtils.PreviewOverworldProgression(
        classicGames,
        cursor.currentMinigameIndex
      );

      // keep the progression with more progress...
      if (
        Object.keys(prev.progress ?? {}) >= Object.keys(next.progress ?? {})
      ) {
        return prev;
      }
      return next;
    });
    setView('overworld');
  });

  const handleClickOverworldGame = useLiveCallback((game: Game) => {
    setCursor(cursor.withMinigame(game.id));
    setView('playground');
  });

  return view === 'overworld' ? (
    <div className='relative w-full h-full'>
      <Overworld
        pack={classicGamePack}
        games={classicGames}
        progression={overworldProgression}
        isPreview
        onClickGame={handleClickOverworldGame}
        displayOptions={{
          showLpLogo: false,
          logoPlaceholderSpacing: 'h-12',
        }}
      />
      <div className='fixed top-3.5 left-3 right-3 z-15 flex items-center justify-end text-white'>
        {isOrgAdmin && (
          <Link to={$path('/learning/admin/my-courses')}>
            <StackedSquaresIcon />
          </Link>
        )}
      </div>
    </div>
  ) : (
    <Playground
      cursor={cursor}
      preview
      onClose={() => handlePlaygroundClose(cursor)}
      onMinigameContinue={() => {
        handlePlaygroundClose(cursor.withNextMinigame());
      }}
      getUser={() => user}
    />
  );
}
